import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import * as Styled from './RotateDevice.styles';
import Logo from 'components/Logo';

interface RotateDeviceProps {
  uvLight: boolean;
}

const RotateDevice: React.FC<RotateDeviceProps> = ({ uvLight }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Styled.Wrapper uvLight={uvLight}>
      <div className="logo-wrapper">
        <Logo light={uvLight} />
      </div>
      <div className="content">
        <h1>{parse(t('rotateDevice.title'))}</h1>
      </div>
    </Styled.Wrapper>
  );
};

export default RotateDevice;
