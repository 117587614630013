/* eslint-disable @typescript-eslint/no-var-requires */
import { css } from 'styled-components';

const Channel4ChadwickBoldWoff = require('../assets/fonts/Channel4Chadwick-Bold.woff');
const Channel4ChadwickBoldWoff2 = require('../assets/fonts/Channel4Chadwick-Bold.woff2');
const Channel4ChadwickExtraLightWoff = require('../assets/fonts/Channel4Chadwick-ExtraLight.woff');
const Channel4ChadwickExtraLightWoff2 = require('../assets/fonts/Channel4Chadwick-ExtraLight.woff2');
const Channel4ChadwickLightWoff = require('../assets/fonts/Channel4Chadwick-Light.woff');
const Channel4ChadwickLightWoff2 = require('../assets/fonts/Channel4Chadwick-Light.woff2');
const Channel4ChadwickMediumWoff = require('../assets/fonts/Channel4Chadwick-Medium.woff');
const Channel4ChadwickMediumWoff2 = require('../assets/fonts/Channel4Chadwick-Medium.woff2');
const Channel4ChadwickRegularWoff = require('../assets/fonts/Channel4Chadwick-Regular.woff');
const Channel4ChadwickRegularWoff2 = require('../assets/fonts/Channel4Chadwick-Regular.woff2');
const Channel4HorseferryBoldWoff = require('../assets/fonts/Channel4Horseferry-Bold.woff');
const Channel4HorseferryBoldWoff2 = require('../assets/fonts/Channel4Horseferry-Bold.woff2');
const Channel4HorseferryExtraBoldItalicWoff = require('../assets/fonts/Channel4Horseferry-ExtraBoldItalic.woff');
const Channel4HorseferryExtraBoldItalicWoff2 = require('../assets/fonts/Channel4Horseferry-ExtraBoldItalic.woff2');
const Channel4HorseferryMediumWoff = require('../assets/fonts/Channel4Horseferry-Medium.woff');
const Channel4HorseferryMediumWoff2 = require('../assets/fonts/Channel4Horseferry-Medium.woff2');
const Channel4HorseferryRegularWoff = require('../assets/fonts/Channel4Horseferry-Regular.woff');
const Channel4HorseferryRegularWoff2 = require('../assets/fonts/Channel4Horseferry-Regular.woff2');
const WaitingfortheSunriseRegularWoff = require('../assets/fonts/WaitingfortheSunrise-Regular.woff');
const WaitingfortheSunriseRegularWoff2 = require('../assets/fonts/WaitingfortheSunrise-Regular.woff2');
const DMMonoWoff = require('../assets/fonts/DMMono-Regular.woff');
const DMMonoWoff2 = require('../assets/fonts/DMMono-Regular.woff2');

// https://developer.mozilla.org/pt-BR/docs/Web/CSS/font-weight

export default css`
  @font-face {
    font-family: 'Channel4Chadwick';
    font-weight: 100;
    font-style: normal;
    font-display: block;
    src: url(${Channel4ChadwickExtraLightWoff2.default}) format('woff2'),
      url(${Channel4ChadwickExtraLightWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Chadwick';
    font-weight: 300;
    font-style: normal;
    font-display: block;
    src: url(${Channel4ChadwickLightWoff2.default}) format('woff2'),
      url(${Channel4ChadwickLightWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Chadwick';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url(${Channel4ChadwickRegularWoff2.default}) format('woff2'),
      url(${Channel4ChadwickRegularWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Chadwick';
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: url(${Channel4ChadwickMediumWoff2.default}) format('woff2'),
      url(${Channel4ChadwickMediumWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Chadwick';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url(${Channel4ChadwickBoldWoff2.default}) format('woff2'),
      url(${Channel4ChadwickBoldWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Horseferry';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url(${Channel4HorseferryRegularWoff2.default}) format('woff2'),
      url(${Channel4HorseferryRegularWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Horseferry';
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: url(${Channel4HorseferryMediumWoff2.default}) format('woff2'),
      url(${Channel4HorseferryMediumWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Horseferry';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url(${Channel4HorseferryBoldWoff2.default}) format('woff2'),
      url(${Channel4HorseferryBoldWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'Channel4Horseferry';
    font-weight: 800;
    font-style: italic;
    font-display: block;
    src: url(${Channel4HorseferryExtraBoldItalicWoff2.default}) format('woff2'),
      url(${Channel4HorseferryExtraBoldItalicWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'WaitingfortheSunrise';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url(${WaitingfortheSunriseRegularWoff2.default}) format('woff2'),
      url(${WaitingfortheSunriseRegularWoff.default}) format('woff');
  }
  @font-face {
    font-family: 'DMMono';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url(${DMMonoWoff2.default}) format('woff2'), url(${DMMonoWoff.default}) format('woff');
  }
`;
