import React from 'react';
import parse from 'html-react-parser';
import * as Styled from './JavaScriptDisabled.styles';
import Logo from 'components/Logo';

import { useTranslation } from 'react-i18next';

interface JavascriptDisabledProps {
  uvLight: boolean;
}

const JavascriptDisabled: React.FC<JavascriptDisabledProps> = ({ uvLight }) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper uvLight={uvLight}>
      <div className="logo-wrapper">
        <Logo light={uvLight} />
      </div>
      <div className="content">
        <h1>{parse(t('javascriptDisabled.title'))}</h1>
        <h5>{parse(t('javascriptDisabled.refresh'))}</h5>
        <div className="text-description">
          <p>{parse(t('javascriptDisabled.chromeDescription'))}</p>
          <span>{parse(t('javascriptDisabled.chromeSettings'))}</span>
        </div>
        <div className="text-description">
          <p>{parse(t('javascriptDisabled.safariDescription'))}</p>
          <span>{parse(t('javascriptDisabled.safariSettings'))}</span>
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default JavascriptDisabled;
