import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import * as Styled from './UnsupportedBrowser.styles';
import 'i18n/config';
import { useTranslation } from 'react-i18next';

import AdobeAnalytics from 'utils/adobeAnalytics';
import Logo from 'components/Logo';
import Chrome from 'assets/images/non-functionals/chrome.png';
import Safari from 'assets/images/non-functionals/safari.png';
import { CtaYellow } from 'components/CtaYellow/CtaYellow';

const IS_DEBUG = process.env.IS_DEBUG && false;

interface UnsupportedBrowserProps {
  setAccept(state: boolean): void;
  uvLight: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UnsupportedBrowserProps = ({ setAccept, uvLight }: UnsupportedBrowserProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    AdobeAnalytics.trackEvent('error_browser', 'pageview');
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Styled.Wrapper uvLight={uvLight}>
      <div className="logo-wrapper">
        <Logo light={uvLight} />
      </div>
      <div className="title-wrapper" data-text="Browser not supported">
        <h1>{parse(t('unsupportedBrowser.title'))}</h1>
      </div>
      <div className="text-wrapper">
        <p>{parse(t('unsupportedBrowser.description'))}</p>
      </div>
      <CtaYellow onClick={() => setAccept(true)} label={`${t('unsupportedBrowser.continue')}`} big />
    </Styled.Wrapper>
  );
};

export default UnsupportedBrowserProps;
