import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import * as Styled from './MobileOnly.styles';
import ImgQr from 'assets/images/non-functionals/qr.png';
import { useTranslation } from 'react-i18next';
import AdobeAnalytics from 'utils/adobeAnalytics';
import useWindowSize from 'hooks/useWindowSize';

interface MobileOnlyProps {}

const MobileOnly: React.FC<MobileOnlyProps> = () => {
  const { t } = useTranslation();
  const [uvLight, setUvLight] = useState(false);
  const { width } = useWindowSize();
  const [isMobileSize, setIsMobileSize] = useState<boolean>();
  const htmlRef = useRef(document.querySelector('html'));

  useEffect(() => {
    AdobeAnalytics.trackEvent('error_desktop', 'pageview');
  }, []);

  useEffect(() => {
    setIsMobileSize(width < 768);
  }, [width]);

  useEffect(() => {
    if (isMobileSize) {
      htmlRef.current.style.fontSize = '1.4vw';
    } else {
      htmlRef.current.style.fontSize = '0.6944444444444444vw';
    }
  }, [isMobileSize]);

  return (
    <Styled.Wrapper uvLight={uvLight}>
      <div className="content">
        <div className="qr-wrapper">
          <img src={ImgQr} alt={t('mobileOnly.title')} />
          <div className="description-wrapper">
            <h1>{t('mobileOnly.title')}</h1>
            <p>{parse(t('mobileOnly.description'))}</p>

            <a href="#">{t('mobileOnly.url')}</a>
          </div>
        </div>
        <div className="uv-slider">
          <div className="slider" onClick={() => setUvLight(!uvLight)}>
            <input type="checkbox" defaultChecked={uvLight} />
            <label className={`button ${uvLight ? 'active' : ''}`}>{uvLight ? 'ON' : 'OFF'}</label>
          </div>
        </div>
      </div>

      <div className="uv-wrapper">
        <div className="uv-layer-background"></div>
        <div className="uv-layer-fingerprint"></div>
        {uvLight && <div className="uv-layer-1"></div>}
      </div>
    </Styled.Wrapper>
  );
};

export default MobileOnly;
