import { createGlobalStyle } from 'styled-components';

import fonts from 'styles/fonts';
import { rect, vh } from 'styles/mixins';
import { colors, ease } from 'styles/vars';
import { dynamicFontSize } from 'styles/responsive';
import { fontChannel4Chadwick } from 'styles/typography';

export default createGlobalStyle`
  ${fonts}

  :root {
    --vh: 1vh;
  }

  html {
    ${dynamicFontSize()}
    color: ${colors.white};
    box-sizing: border-box;
    background: ${colors.black};
    transition: background-color 0.6s ${ease.outExpo};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
    transition: height 200ms;
  }

  body {
    ${fontChannel4Chadwick()}
    word-break: keep-all;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    touch-action: pan-x pan-y;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    user-select: none;
    -webkit-touch-callout: none;
  }

  main {
    width: 100%;
    min-height: 100vh;
    min-height: ${vh(100)};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  dl,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li,
  dt,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote {
    margin: 0;
    padding: 0;
  }

  button,
  input,
  a,
  h1,
  div,
  p {
    &:focus:not(.focus-visible) {
      outline: none;
    }
  }

  button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    text-align: inherit;
    letter-spacing: inherit;
    font: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
    margin: 0;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: currentColor;
  }

  svg {
    display: block;
    fill: currentColor;
  }
`;
