import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { isMobile, isSupportedBrowser, isTablet } from 'utils/platform';
import { ROUTES } from 'utils/routes';
import useWindowSize from 'hooks/useWindowSize';
import Head from 'u9/components/Head';
import NotFound from './NotFound/NotFound';
import MobileOnly from './MobileOnly/MobileOnly';
import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import RotateDevice from './RotateDevice/RotateDevice';
import UnsupportedBrowser from './UnsupportedBrowser/UnsupportedBrowser';

interface NonFunctionalsProps {
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
  landingPageCopy: any;
}

const NonFunctionals = ({ children, landingPageCopy, isBypassed = false }: NonFunctionalsProps): JSX.Element => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(false);
  const [isSubStandardBrowserAccepted, setSubstandardBrowserAccepted] = useState(false);
  const [shouldRotate, setRotate] = useState(false);
  const router = useRouter();
  const hasMatchingRoute = Object.values(ROUTES).includes(router.route as typeof ROUTES[keyof typeof ROUTES]);
  const isShareRoute = router.route.includes(ROUTES.SHARE);

  const windowSize = useWindowSize();

  useEffect(() => {
    setMounted(true);
    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
  }, [router.asPath]);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, needsUpgrade, fromSocial } = isSupportedBrowser();

      setShowUnsupportedBrowser((!isSupported || needsUpgrade) && !fromSocial);
    }
  }, [isMounted]);

  useEffect(() => {
    setRotate((isMobile() && windowSize.isLandscape) || (isTablet() && !windowSize.isLandscape));
  }, [windowSize]);

  if (isBypassed) return <>{children}</>;

  let errorComponent = null;
  if (!isRendered) {
    errorComponent = <JavaScriptDisabled uvLight={true} />;
  } else if (!isMobile()) {
    errorComponent = <MobileOnly />;
  } else if (showUnsupportedBrowser && !isSubStandardBrowserAccepted) {
    errorComponent = <UnsupportedBrowser setAccept={setSubstandardBrowserAccepted} uvLight={true} />;
  } else if (!hasMatchingRoute) {
    errorComponent = <NotFound uvLight={true} />;
  }

  // console.error('errorComponent', isShareRoute);
  // console.error('errorComponent', router.asPath);
  if (isShareRoute) {
    errorComponent = null;
  }

  return (
    <>
      {!isShareRoute && <Head {...landingPageCopy?.head} asPath={router.asPath} />}
      {errorComponent || (
        <>
          {shouldRotate && <RotateDevice uvLight={true} />}
          {children}
        </>
      )}
    </>
  );
};

export default React.memo(NonFunctionals);
