import styled from 'styled-components';
import { colors } from 'styles/vars';
import { rect, vh, px } from 'styles/mixins';
import { mediaMobile } from 'styles/responsive';

import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';

import bgImage from 'assets/images/game-1-intro-tutorial-bg.jpg';
import uvBgImage from 'assets/images/non-functionals/uv-layer-browser-not-supported.png';

export const Wrapper = styled.div<{ uvLight: boolean }>`
  ${rect('relative', 0, 0, '100%', vh(100))}
  touch-action: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: 50% 50%;
  background-image: ${({ uvLight }) => (uvLight ? `url(${uvBgImage})` : `url(${bgImage})`)};

  .logo-wrapper {
    margin: 1.7rem auto 1.8rem;
    align-self: flex-start;
  }

  .title-wrapper {
    margin-top: 2rem;

    h1 {
      ${fontChannel4Horseferry(75, 700, 0.8)};
      letter-spacing: -0.5rem;
      text-transform: uppercase;
      color: ${({ uvLight }) => (uvLight ? `${colors.uv}` : `${colors.blackBeluga}`)};
      text-align: right;
      margin-right: 2rem;
      ${mediaMobile(`
        margin-right: 1rem;
        font-size: 6rem;
      `)}
    }

    span {
      display: block;
      text-align: left;
      margin-left: 1.5rem;
    }
  }

  .text-wrapper {
    width: 80vw;
    margin-top: 6.5rem;

    p {
      ${fontChannel4Horseferry(20, 700, 1.1)};
      text-transform: uppercase;
      color: ${({ uvLight }) => (uvLight ? `${colors.white}` : `${colors.blackPearl}`)};
      text-align: center;
    }
  }

  button {
    margin-top: ${px(50)};
  }
`;
