import styled, { css, keyframes } from 'styled-components';
import { colors } from 'styles/vars';
import { rect, vh } from 'styles/mixins';
import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';
import bgImage from 'assets/images/game-1-intro-tutorial-bg.jpg';
import uvBgImage from 'assets/images/non-functionals/uv-layer-browser-not-supported.png';

/* Don't show it immediately or it'll flash briefly when the page loads  */
export const Wrapper = styled.noscript<{ uvLight: boolean }>`
  ${rect('relative', 0, 0, '100%', vh(100))}
  touch-action: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: 50% 50%;
  background-image: ${({ uvLight }) => (uvLight ? `url(${uvBgImage})` : `url(${bgImage})`)};

  .logo-wrapper {
    margin: 1.7rem auto 1.8rem;
    align-self: flex-start;
  }

  .content {
    margin-top: 3rem;
    padding: 0 1.3rem 0 0.8rem;

    h1,
    h5,
    p,
    span {
      color: ${({ uvLight }) => (uvLight ? `${colors.uv}` : `${colors.blackBeluga}`)};
      text-align: center;
      text-transform: uppercase;
    }

    h1 {
      ${fontChannel4Horseferry(75, 700, 0.8)};
      letter-spacing: -0.5rem;
      text-align: right;

      span {
        display: block;
        text-align: left;
      }
    }

    h5 {
      ${fontChannel4Horseferry(20, 700, 1.1)};
      color: ${({ uvLight }) => (uvLight ? `${colors.white}` : `${colors.blackBeluga}`)};
      margin-top: 8.8rem;
    }

    .text-description {
      margin: 3.7rem auto 0;
      text-align: center;

      & + .text-description {
        margin: 4rem auto 0;

        p {
          margin: 0 auto 0.4rem;
        }
      }

      p {
        ${fontChannel4Chadwick(12, 500, 1.1)}
        color: ${({ uvLight }) => (uvLight ? `${colors.lightGreen}` : `${colors.blackBeluga}`)};
        letter-spacing: 0.05rem;
        width: 60%;
        margin: 0 auto 1rem;
      }

      span {
        ${fontChannel4Chadwick(14, 700, 1.1)}
        letter-spacing: 0.075rem;
      }
    }
  }
`;

export const Content = styled.div``;
