import styled from 'styled-components';
import { colors } from 'styles/vars';
import { rect, vh } from 'styles/mixins';
import { mediaMobile } from 'styles/responsive';

import bgImage from 'assets/images/game-1-intro-tutorial-bg.jpg';
import uvBgImage from 'assets/images/non-functionals/uv-rotate-bg.png';
import rotateImg from 'assets/images/non-functionals/rotate.png';
import uvRotateImg from 'assets/images/non-functionals/uv-rotate.png';
import { fontChannel4Horseferry } from 'styles/typography';

export const Wrapper = styled.div<{ uvLight: boolean }>`
  ${rect('relative', 0, 0, '100%', vh(100))}
  position: fixed;
  touch-action: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: 50% 50%;
  background-image: ${({ uvLight }) => (uvLight ? `url(${uvBgImage})` : 'none')};
  z-index: 9999;

  &:before {
    content: ${({ uvLight }) => (uvLight ? '' : '""')};
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(var(--vh) * 100);
    height: 100vw;
    background: url(${bgImage}) center top no-repeat;
    background-size: cover;
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .logo-wrapper {
    margin: 7.7rem auto 0;
    transform: scale(2.1);
    ${mediaMobile(`
      margin: 0 auto;
      transform: scale(0.6);
    `)}
  }

  .content {
    width: 100%;

    h1 {
      ${fontChannel4Horseferry(135, 700, 0.8)};
      letter-spacing: -0.6rem;
      text-transform: uppercase;
      color: ${({ uvLight }) => (uvLight ? `${colors.uv}` : `${colors.blackBeluga}`)};
      transform: rotate(-30deg);
      width: 54%;
      margin: 8rem auto 0 31rem;
      text-align: left;

      ${mediaMobile(`
        font-size: 5rem;
        letter-spacing: -0.3rem;
        margin: 2rem auto 0rem 8rem;
      `)}

      span {
        display: block;
        text-align: right;
        margin-right: 9rem;
        ${mediaMobile(`
          margin-right: -2rem;
        `)}

        & + span {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin: -1rem 1rem 0 0;
          ${mediaMobile(`
            margin: -0.6rem -5rem 0 0;
          `)}

          &:after {
            content: '';
            width: 13rem;
            height: 11rem;
            background-size: cover;
            background-position: 50% 50%;
            background-image: ${({ uvLight }) => (uvLight ? `url(${uvRotateImg})` : `url(${rotateImg})`)};
            margin-left: -1rem;
            ${mediaMobile(`
              width: 4.5rem;
              height: 4.5rem;
              margin-left: -0.5rem;
            `)}
          }
        }
      }
    }
  }
`;
