import styled from 'styled-components';
import { colors } from 'styles/vars';
import { rect, vh } from 'styles/mixins';
import { mediaDesktop, mediaLarge } from 'styles/responsive';

import bgImage from 'assets/images/non-functionals/uv-desktop.jpg';
import uvBgImage from 'assets/images/non-functionals/uv-light-desktop.jpg';
import uvFingerPrint from 'assets/images/non-functionals/uv-fingerPrint.png';
import uvLayer1 from 'assets/images/non-functionals/uv-layer-1.png';
import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';

export const Wrapper = styled.div<{ uvLight: boolean }>`
  ${rect('relative', 0, 0, '100%', vh(100))}
  touch-action: none;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-image: ${props => (props.uvLight ? `url(${uvBgImage})` : `url(${bgImage})`)};
  background-size: cover;
  background-position: 50% 50%;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    h1,
    a,
    p {
      text-transform: uppercase;
    }

    h1 {
      ${fontChannel4Horseferry(23, 700, 1.1)};
      color: ${colors.blackBeluga};
      letter-spacing: -0.05rem;
    }

    .qr-wrapper {
      position: absolute;
      bottom: 9%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: stretch;
      width: 58rem;
      padding: 3.8rem;
      background-color: ${colors.white};
    }

    img {
      width: 100%;
      height: auto;
      max-width: 17rem;
      max-height: 17rem;
    }

    .description-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 2rem;

      a {
        ${fontChannel4Horseferry(18, 700, 1.1)};
        color: ${colors.blackBeluga};
        text-decoration: none;
      }

      p {
        ${fontChannel4Chadwick(12, 500, 1.1)};
        color: ${colors.blackBeluga};
        letter-spacing: 0.05rem;
        width: 86%;
        margin: 0 auto;
      }
    }
    .uv-slider {
      position: absolute;
      bottom: 6.3%;
      left: 4.3%;
      display: flex;
      z-index: 10;

      .slider {
        position: relative;
        width: 8rem;
        height: 4.8rem;
        background-color: ${colors.darkCerulean};
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 15.84rem;
      }

      input {
        display: none;

        &:checked {
          & + label {
            transform: translate(86%, -50%);
          }
        }
      }

      .button {
        position: absolute;
        top: 50%;
        left: 4%;
        transform: translateY(-50%);
        transition: 0.3s linear;
        width: 4rem;
        height: 4rem;
        background: linear-gradient(165.83deg, #e4eef4 13.29%, rgba(255, 255, 255, 0) 118.39%);
        box-shadow: 0px 1.49333px 0.48px rgba(0, 0, 0, 0.1), 0px 1.6px 3.2px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        ${fontChannel4Chadwick(14, 700, 1.1)};
        color: ${colors.darkCerulean};
        line-height: 4rem;
        letter-spacing: -0.01rem;

        &.active {
          background: linear-gradient(335.73deg, #0164ea -9.12%, #10cde8 43.75%);
          transform: translate(86%, -50%);
        }
      }
      p {
      }
    }
  }
  .uv-wrapper {
    .uv-layer-background {
      background-image: url(${uvLayer1});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 70%;
      width: 53%;
      mix-blend-mode: hard-light;
      opacity: 0.7;
    }
    .uv-layer-fingerprint {
      background-image: url(${uvFingerPrint});
      background-size: contain;
      background-position: bottom left;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 14rem;
      width: 38.3rem;
    }
  }
`;
