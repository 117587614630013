import React, { useEffect, useState } from 'react';
import NextHead from 'next/head';
import useWindowSize from 'hooks/useWindowSize';
import { isAndroid, isInputFocused } from 'utils/platform';
import { IS_LIVE_ENV } from 'config/config';
import ShareService from 'services/share.service';

export interface HeadProps {
  asPath: any;
}

const defaultViewport =
  'width=device-width, initial-scale=1.0, shrink-to-fit=no, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover';

const Head: React.FunctionComponent<HeadProps> = ({ asPath }) => {
  const [viewport, setViewport] = useState<string>(defaultViewport);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isAndroid() && !isInputFocused()) {
      setViewport(
        `height=${windowSize.height},width=${windowSize.width},initial-scale=1.0,maximum-scale=1.0,user-scalable=no`
      );
    }
  }, [windowSize]);

  return (
    <NextHead>
      <title>{'Murder Island'}</title>
      <meta name="title" content="Could you catch a killer? Murder Island starts 5 Oct 9.30pm" />
      <meta name="description" content="Could you catch a killer? Murder Island starts 5 Oct 9.30pm" />
      <link rel="icon" href={'/favicon.ico'} />
      <link rel="manifest" href={'/manifest.json'} />
      <meta property="og:url" content={'https://murderisland.channel4.com/'} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={ShareService.instance.getOgImage(asPath)} />
      <meta property="twitter:image" content={ShareService.instance.getOgImage(asPath)} />
      <meta property="og:title" content="Murder Island" />
      <meta property="og:description" content="Could you catch a killer? Murder Island starts 5 Oct 9.30pm" />
      <meta property="twitter:title" content="Murder Island" />
      <meta property="twitter:description" content="Could you catch a killer? Murder Island starts 5 Oct 9.30pm" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="viewport" content={viewport} />
      {process.env.PWA && process.env.ENV === 'production' && (
        <>
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <link rel="icon" type="image/png" sizes="32x32" href={'/favicon-32x32.png'} />
          <link rel="icon" type="image/png" sizes="16x16" href={'/favicon-16x16.png'} />
          <link rel="manifest" href={'/site.webmanifest'} />
          <link rel="mask-icon" href={'/safari-pinned-tab.svg'} color="#ffffff" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
          <link rel="apple-touch-icon" sizes="120x120" href={'/apple-touch-icon-120.png'} />
          <link rel="apple-touch-icon" sizes="152x152" href={'/apple-touch-icon-152.png'} />
          <link rel="apple-touch-icon" sizes="180x180" href={'/apple-touch-icon-180.png'} />
        </>
      )}

      {IS_LIVE_ENV() ? (
        <script
          src="https://assets.adobedtm.com/17abb0d42a9b/341cfd95259e/launch-EN218f2af14aa44ff38ede9e26315800a6.min.js"
          async
        ></script>
      ) : (
        <script
          src="https://assets.adobedtm.com/17abb0d42a9b/341cfd95259e/launch-ENbc8b722ae4c342f990e214eb555b33c3-staging.min.js"
          async
        ></script>
      )}
    </NextHead>
  );
};

export default Head;
