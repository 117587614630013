import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import AdobeAnalytics from 'utils/adobeAnalytics';

import * as Styled from './NotFound.styles';
import Logo from 'components/Logo';
import Image404 from 'assets/images/non-functionals/404.png';
import UvImage404 from 'assets/images/non-functionals/uv-404.png';
import CtaYellow from 'components/CtaYellow';

interface NotFoundProps {
  uvLight: boolean;
}

const NotFound: React.FC<NotFoundProps> = ({ uvLight }) => {
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    AdobeAnalytics.trackEvent('error_404', 'pageview');
  }, []);

  return (
    <Styled.Wrapper uvLight={uvLight}>
      <div className="logo-wrapper">
        <Logo light={uvLight} />
      </div>
      {!uvLight && (
        <>
          <Styled.BackgroundLayer1 />
          <Styled.BackgroundLayer2 />
        </>
      )}
      <div className="content">
        {uvLight ? <img src={UvImage404} alt="404" /> : <img src={Image404} alt="404" />}

        <h2>The clues have lead you to the wrong place.</h2>
        <p>There&apos;s nothing to see here. Tap the button below to get back to solving the crimes.</p>
        <div className="button-wrapper">
          <CtaYellow
            label="Go Back Home"
            onClick={() => {
              router.push('/');
            }}
          />
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default NotFound;
