import styled from 'styled-components';
import { colors } from 'styles/vars';
import { rect, vh } from 'styles/mixins';
import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';

import bgImage from 'assets/images/game-1-intro-tutorial-bg.jpg';
import bloodBg from 'assets/images/non-functionals/blood.png';
import paperBg from 'assets/images/non-functionals/paper.png';
import uvBgImage from 'assets/images/non-functionals/uv-404-background.png';

export const Wrapper = styled.div<{ uvLight: boolean }>`
  ${rect('relative', 0, 0, '100%', vh(100))}
  touch-action: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: 50% 50%;
  background-image: ${({ uvLight }) => (uvLight ? `url(${uvBgImage})` : `url(${bgImage})`)};

  .logo-wrapper {
    margin: 1.7rem auto 1.8rem;
    align-self: flex-start;
  }

  .content {
    ${rect('relative', 0, 0, '100%', '100%')}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    img {
      margin: ${({ uvLight }) => (uvLight ? '0 auto 16rem' : '0 0 7rem 0')};
      width: 100%;
      max-width: ${({ uvLight }) => (uvLight ? '71%' : 'initial')};
    }

    h2,
    p {
      width: 60vw;
      text-transform: uppercase;
      text-align: center;
    }

    h2 {
      ${fontChannel4Horseferry(24, 700, 1.1)};
      letter-spacing: -0.05rem;
      margin: ${({ uvLight }) => (uvLight ? '0 auto 2.3rem' : '0 auto 2rem')};
      color: ${({ uvLight }) => (uvLight ? `${colors.white}` : `${colors.blackPearl}`)};
    }

    p {
      ${fontChannel4Chadwick(14, 500, 1.1)};
      letter-spacing: 0.075rem;
      color: ${({ uvLight }) => (uvLight ? `${colors.lightGreen}` : `${colors.blackPearl}`)};
      margin: 0 auto 6rem;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 14rem;

    button {
      border: 0;
      padding: 1.7rem 5rem;
      background-color: ${({ uvLight }) => (uvLight ? `${colors.uv}` : `${colors.yellow}`)};
      background-image: ${({ uvLight }) => (uvLight ? 'none' : '')};

      .label {
        ${fontChannel4Horseferry(24, 700, 1.1)};
        letter-spacing: -0.05rem;
        color: ${colors.black};
      }
    }
  }
`;

export const BackgroundLayer1 = styled.div`
  position: absolute;
  top: -9rem;
  right: 0;
  height: 37rem;
  width: 29rem;
  background: url(${bloodBg}) right top no-repeat;
  background-size: cover;
  mix-blend-mode: darken;
`;

export const BackgroundLayer2 = styled.div`
  position: absolute;
  top: -5rem;
  right: 0;
  height: 34rem;
  width: 21rem;
  background: url(${paperBg}) right top no-repeat;
  background-size: contain;
  mix-blend-mode: color-burn;
`;

export const UvBackground = styled.div``;
