import React from 'react';
import type { AppProps } from 'next/app';
import { AnimatePresence } from 'framer-motion';
import gsap from 'gsap';
import 'normalize.css';

import Version from 'components/Version';
import NonFunctionals from 'components/NonFunctionals/NonFunctionals';

import GlobalStyles from 'styles/global';
import { HistoryProvider } from 'context/history';
import { HeaderProvider } from 'context/header';
import { ProgressProvider } from 'context/progress';
import { RedirectsProvider } from 'context/redirects';
import { SettingsProvider } from 'context/settings';

if (typeof window !== 'undefined') {
  // GSAP config
  gsap.defaults({ duration: 1, ease: 'none' });
  gsap.registerPlugin(require('gsap/dist/Flip').default);
  gsap.registerPlugin(require('gsap/dist/SplitText').default);
  gsap.registerPlugin(require('gsap/dist/CustomEase').default);
  gsap.registerPlugin(require('gsap/dist/CustomWiggle').default);
  gsap.registerPlugin(require('gsap/dist/DrawSVGPlugin').default);
  gsap.registerPlugin(require('gsap/dist/ScrollToPlugin').default);
  gsap.registerPlugin(require('gsap/dist/ScrambleTextPlugin').default);
  // ...
}

const App = ({ Component, pageProps, router }: AppProps) => (
  <>
    <GlobalStyles />
    <ProgressProvider>
      <SettingsProvider>
        <HistoryProvider>
          <RedirectsProvider>
            <HeaderProvider>
              <NonFunctionals {...pageProps}>
                <AnimatePresence exitBeforeEnter={true} initial={false}>
                  <Component key={router.route} router={router} {...pageProps} />
                  {process.env.ENV !== 'production' && <Version />}
                </AnimatePresence>
              </NonFunctionals>
            </HeaderProvider>
          </RedirectsProvider>
        </HistoryProvider>
      </SettingsProvider>
    </ProgressProvider>
  </>
);

export default App;
